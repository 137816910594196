import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
class CardGeneral extends React.Component {
  render() {
    return (
      <div
        className={
          'rounded-2xl mb-2 shadow bg-white px-8 py-6 ' +
          (this.props.className ? ' ' + this.props.className : null)
        }
      >
        {this.props.title ? (
          <div>
            <h3 className="text-brand-500 font-black text-xl md:text-2xl font-display">{this.props.title}</h3>
          </div>
        ) : null}
        <div className="card-body pt-2">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default CardGeneral
